<template>
  <v-toolbar class="tertiary" flat>
    <v-toolbar-title v-if="selected.length >= 1">
      {{ selected.length }} {{selected.length > 1 ? 'itens selecionados' : 'item selecionado'}}
    </v-toolbar-title>    
    <v-toolbar-title v-else>
      {{ $route.meta.title }}
    </v-toolbar-title>
    <v-spacer/>
      <v-btn @click="$emit('new')" class="primary tertiary--text elevation-0">
        <v-icon small>mdi-plus-circle</v-icon>
        <span class="overline ml-2">Novo</span>
      </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    selected: Array,
    route: String
  },
}
</script>